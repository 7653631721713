<template>
  <div>
    <component :is="appropriateComponent" v-if="appropriateComponent" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",

  metaInfo: {
    title: "Dashboard",
  },

  components: {
    Campaigns: () => import("@/views/campaigns/Campaigns"),
    StreamerDashboard: () => import("@/views/streamer/StreamerDashboard"),
    OrganizationDashboard: () => import("@/views/organization/OrganizationDashboard"),
  },

  computed: {
    ...mapGetters(["getUserInfo", "getOrganization"]),

    appropriateComponent() {
      if (this.getOrganization?._id === "6104316858d6180c099272b9") {
        return "Campaigns";
      }

      if (this.getUserInfo?.type === "streamer") {
        return "StreamerDashboard";
      }

      if (this.getOrganization?._id) {
        return "OrganizationDashboard";
      }

      return null;
    },
  },
};
</script>
